import { Model } from '@vuex-orm/core';
import { Task, TaskInterface, Timesheet, TimesheetInterface, Technician, Payment } from '@/store/modules';
import { MRNote } from './Note';



export interface MRInterface {
  id: number;
  taskID?: number;
  signatureID?: string | null;
  task?: TaskInterface;
  time?: TimesheetInterface[];
  price?: number;
  hours?: number;
  payments?: Payment[];
  address_data?: string;
  taskNumber?: string;
  customer_name?: string;
  notes?: MRNote[];

  approved?: boolean;
  voided?: boolean;
  completed?: boolean;
  paid?: boolean;

}

export class MR extends Model implements MRInterface {

  id!: number;
  time?: TimesheetInterface[];
  price?: number;
  cost?: number;
  payments?: Payment[];
  notes?: MRNote[];
  address_data?: string;
  taskNumber?: string;
  customer_name?: string;
  customerID?: string;

  approved?: boolean;
  voided?: boolean;
  completed?: boolean;
  paid?: boolean;
  hours?: number;
  signatureID?: string | null;

  static entity = 'mr';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      saving: false,
      error: []
    }
  }

  static isSaving() {
    return this.store().state.entities[this.entity].saving;
  }

  static getErrors() {
    return this.store().state.entities[this.entity].error;
  }

  total() {
    return this
  }

  static fields() {
    return {
      id: this.attr(null),
      taskID: this.number(null).nullable(),
      paymentID: this.number(null).nullable(),
      signatureID: this.string(null).nullable(),
      techID: this.number(null).nullable(),
      hours: this.number(null).nullable(),
      price: this.number(null).nullable(),
      vehicleCost: this.number(null).nullable(),
      cost: this.number(null).nullable(),

      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      jobNumber: this.string(null).nullable(),
      taskNumber: this.string(null).nullable(),
      scheduled: this.string(null).nullable(),
      approved: this.boolean(null).nullable(),
      voided: this.boolean(null).nullable(),

      // not supported yet
      completed: this.boolean(false).nullable(),
      paid: this.boolean(false).nullable(),

      time: this.hasMany(Timesheet, 'mrID'),
      task: this.belongsTo(Task, 'taskID', 'ID'),
      tech: this.belongsTo(Technician, 'techID', 'ID'),
      payments: this.hasMany(Payment, 'mrID'),
      address_data: this.string(null).nullable(),
      customer_name: this.string(null).nullable(),
      customerID: this.string(null).nullable(),
      tech_name: this.string(null).nullable(),
      // normalized from Notes
      notes: this.hasMany(MRNote, 'mrID')
    };
  }
}

export default MR;