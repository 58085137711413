import AuthRepository from "@/auth/AuthRepository";
import { authService } from '@/auth/AuthService';
import { AuthTokenPayload, authTokenStorage } from '@/auth/AuthTokenStorage';
import store from '@/store';
import { AuthState } from '@/store/types';
import { parseJwt } from '@/utility';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { RegisterAwsDto } from '../../../auth/dto/RegisterAws.dto';
import { AuthLoginPayload } from './types';

// const authPrefix = 'auth__';

// const getAuth = (k: keyof AuthState) => tokenStorage.getStorage().getItem(`${authPrefix}${k}`);

// const setAuth = <K extends keyof AuthState>(
//   k: K, v: AuthState[K]
// ) => {
//   const s = tokenStorage.getStorage();
//   const sk = `${authPrefix}${k}`;
//   if (!(typeof v === 'string')) {
//     s.removeItem(sk);
//     return;
//   }
//   s.setItem(sk, v)
// }

// const removeItem = <K extends keyof AuthState>(k: K) => {
//   const sk = `${authPrefix}${k}`;
//   tokenStorage.getStorage().removeItem(sk);
// }

function cleanMutationValue(val: string | null | undefined) {
  if (val && val !== '') {
    return val;
  }
  return null;
}

function parseErrResponse(err: any) {
  if (typeof err === 'string') {
    return err;
  } else if (typeof err === 'object') {
    return err.message;
  }
  return null;
}

// function syncStorage(o: AuthState) {
//   stateKeys.forEach((k: keyof AuthState) => setAuth(k, o[k]))
// }

// function setStorage<K extends keyof AuthState>(k: keyof AuthState, v: AuthState[K]) {
//   setAuth(k, v)
// }


const stateKeys: Array<keyof AuthState> = [
  'user_id', 'user_email', 'access_token', 'access_session',
  'refresh_token', 'status', 'error'
];

// type TX = keyof Partial<AuthTokenPayload>;

const serializeMap = new Map<keyof AuthState, keyof Partial<AuthTokenPayload>>([
  ['user_email', 'userEmail'],
  ['user_id', 'ID'],
  ['access_token', 'token'],
  ['access_session', 'session'],
  ['refresh_token', 'refresh']
]);

const storage = {
  serialize: async (state: AuthState) => {
    const data: Partial<AuthTokenPayload> = {};
    for (const [k1, k2] of serializeMap) {
      const v = state[k1];
      if (v) {
        data[k2] = v;
      }
    }
    console.log('serialize', data);
    return authTokenStorage.saveAuth(data as AuthTokenPayload);
  }
};

// console.warn('d', store);

@Module({ dynamic: true, store, name: 'auth' })
export class AuthModule extends VuexModule implements AuthState {
  // user_id = getAuth('user_id');
  // user_email = getAuth('user_email');
  // access_token = getAuth('access_token');
  // access_session = getAuth('access_session');
  // refresh_token = getAuth('refresh_token');


  user_id: string | null = '';

  /**
   * @deprecated Either fetch the current login user or include within jwt payload
   */
  user_email: string | null = '';
  access_token: string | null = '';
  access_session: string | null = '';
  refresh_token: string | null = '';
  status: string | null = null;
  error: string | null = null;

  get userId() {
    return this.user_id;
  }

  get email() {
    return this.user_email;
  }

  get accessToken() {
    return this.access_token;
  }

  get accessSession() {
    return this.access_session;
  }

  get refreshToken() {
    return this.refresh_token;
  }

  get authStatus() {
    return this.status;
  }

  get isAuthenticated(): boolean {
    return !!this.access_token;
  }

  get hasError(): boolean {
    return !!this.error;
  }

  get authState(): AuthState {
    return {
      user_id: this.user_id,
      user_email: this.user_email,
      access_token: this.access_token,
      access_session: this.access_session,
      refresh_token: this.refresh_token,
      status: this.status,
      error: this.error,

    }
  }

  @Action
  public async loadStorage() {
    const data = await authTokenStorage.getAuth();
    // console.log('load storage', data)
    if (data) {
      this.AUTH_SET_USER_ID(data.ID);
      this.AUTH_SET_EMAIL(data.userEmail);
      this.AUTH_SET_ACCESS_SESSION(data.session);
      this.AUTH_SET_ACCESS_TOKEN(data.token);
      this.AUTH_SET_REFRESH_TOKEN(data.refresh);
      // storage.serialize(this.authState);
    }

    // console.log('sadas', this.authState)
    // storage.serialize(this.authState);
    // console.log('kek', data);

  }


  @Mutation
  private AUTH_CLEAR_ALL() {
    authTokenStorage.clear();
    stateKeys.forEach((k: keyof AuthState) => {
      this[k] = null;
      // removeItem(k);
    })
  }

  @Mutation
  private AUTH_SET_USER_ID(userId: AuthState["user_id"]) {
    this.user_id = cleanMutationValue(userId);
    // setStorage("user_id", userId);
  }

  @Mutation
  private AUTH_SET_EMAIL(email: AuthState["user_email"]) {
    this.user_email = cleanMutationValue(email);
    // setStorage("user_email", email);
  }

  @Mutation
  private AUTH_SET_ACCESS_TOKEN(tok: AuthState["access_token"]) {
    this.access_token = cleanMutationValue(tok);
    // setStorage("access_token", tok);
  }

  @Mutation
  private AUTH_SET_ACCESS_SESSION(tok: AuthState["access_session"]) {
    this.access_session = cleanMutationValue(tok);
    // setStorage("access_session", tok);
  }

  @Mutation
  private AUTH_SET_REFRESH_TOKEN(tok: AuthState["refresh_token"]) {
    this.refresh_token = cleanMutationValue(tok);
    // setStorage("refresh_token", tok);
  }

  @Mutation
  private AUTH_SET_STATUS(status: AuthState["status"]) {
    this.status = cleanMutationValue(status);
  }

  @Mutation
  private AUTH_SET_ERROR(error: AuthState["error"]) {
    this.error = cleanMutationValue(error);
  }

  // @Action
  // public async register(payload: RegisterAwsDto) {
  //   const { data } = await authService.register(payload);

  // }

  @Action
  public async logOut() {
    try {
      this.AUTH_CLEAR_ALL();
      // removed at the behest of backend dev
      // const r = await authService.logout({ username: this.user_id || '' });
      this.AUTH_SET_STATUS('sign_out__success');
      return {};
    } catch (e) {
      this.AUTH_SET_STATUS('sign_out__error');
      let msg = parseErrResponse(e);
      this.AUTH_SET_ERROR((msg !== null ? msg : 'Sign up error'));
      return msg;
    }
  }


  @Action
  public async logIn({ username, password }: AuthLoginPayload) {
    const { data } = await authService.login({ username, password });
    if (!data) {
      return;
    }

    console.log('login action', data);

    try {
      switch (data.state) {
        case 'challenge':
          alert('Not supported yet');
          break;
        case 'okay':
          // await authTokenStorage.saveAuth(data);
          this.AUTH_SET_USER_ID(data.ID);
          this.AUTH_SET_EMAIL(username);
          this.AUTH_SET_ACCESS_SESSION(data.session);
          this.AUTH_SET_ACCESS_TOKEN(data.token);
          this.AUTH_SET_REFRESH_TOKEN(data.refresh);
          this.AUTH_SET_STATUS('sign_in__success');
          break;
        default:
          throw Error('Failed to login');
      }
    } catch (e) {
      console.error(e);
      const msg = parseErrResponse(e);
      this.AUTH_SET_STATUS('sign_in__error');
      this.AUTH_SET_ERROR((msg !== null ? msg : 'Sign in error'));
    }

    await storage.serialize(this.authState);

    return data;
  }

  /**
   * rawError needed to work for some reason... 
   * https://github.com/championswimmer/vuex-module-decorators/issues/86
   */
  @Action({ rawError: true })
  public async checkLogin() {
    await this.loadStorage();
    // this.AUTH_SET_STATUS('refresh__request');
    const { accessToken, refreshToken } = this;
    try {
      if (!accessToken || !refreshToken) {
        throw 'Missing access or refresh token';
      }

      // add local check for auth token
      const { exp } = parseJwt(accessToken);
      const now = (Date.now() / 1000) + 1;
      if (exp && now < exp) {
        return true;
      }
      console.warn('JWT has expired', now, exp);
      console.log('Attempt to refresh auth');
      await this.authRefresh(refreshToken);
      // const r = await authService.checkLogin();
      // if (r.status === 200) {
      //   const refreshData = await this.authRefresh(refreshToken);
      //   return refreshData.id;
      // }
      // if (r.status !== 200) {
      //   throw `Invalid or bad response(${r.status}): ${r.statusText}`;
      // }
      // throw `Invalid or bad response(${r.status}): ${r.statusText}`;
    } catch (e) {
      const msg = parseErrResponse(e);
      this.AUTH_SET_STATUS('refresh__error');
      this.AUTH_SET_ERROR((msg !== null ? msg : 'Refresh error'));
      throw msg;
    }
    return true;
  }

  @Action
  public async authRefresh(refreshToken: string) {
    const response = await authService.refresh({ token: refreshToken });
    const data = response.data;

    if (!response || response.status !== 200 || !response.data || !response.data.token) {
      throw `Invalid refresh response ${response.status}`;
    }

    this.AUTH_SET_STATUS('refresh__success');
    this.AUTH_SET_ACCESS_TOKEN(data.token);
    if (data.refresh) {
      this.AUTH_SET_REFRESH_TOKEN(data.refresh);
    }

    await storage.serialize(this.authState);

    return data;
  }

  @Action
  public async authChallenge(session: string, { username, new_password }: Required<Omit<AuthLoginPayload, "password">>) {
    const r = await AuthRepository.change(
      username, session, new_password
    );
    const data = r.data;
    this.AUTH_SET_USER_ID(data.ID);
    this.AUTH_SET_EMAIL(username);
    this.AUTH_SET_ACCESS_SESSION(data.session);
    this.AUTH_SET_ACCESS_TOKEN(data.token);
    this.AUTH_SET_REFRESH_TOKEN(data.refresh);

    return data;
  }

  getProperty<K extends keyof T, T = AuthState>(o: T, k: K) {
    return o[k];
  }

  setProperty<K extends keyof T, T = AuthState>(o: T, k: K, v: T[K]) {
    o[k] = v;
  }
}

export default getModule(AuthModule);
// const AuthModule: Module<AuthState, RootState> = {
//     state,
//     actions, 
//     mutations,
//     getters
// };

// export {AuthModule};